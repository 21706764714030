import * as IVSBroadcastClient from 'amazon-ivs-web-broadcast';
import { memo, useCallback, useEffect, useRef, useState } from 'react';

import theme from '~/theme';
import type { Agent } from '~/types/agent';
import type { IvsStream } from '~/types/videoStream';
import logger from '~/utils/logger';

const VIDEO_PLAYER_BACKGROUND_COLOR = '#333333';

interface Props {
  agent: Agent | undefined;
  videoStream: IvsStream | undefined;
}

const VideoStreamPlayerRealtime = memo(({ agent, videoStream }: Props) => {
  const videoElementRef = useRef<HTMLVideoElement>(null);
  const [isJoining, setIsJoining] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const stageRef = useRef<IVSBroadcastClient.Stage>();

  const joinStage = useCallback(async () => {
    if (
      isConnected ||
      isJoining ||
      !videoStream?.playback_participant_token ||
      videoStream?.ivs_stream_state !== 'START'
    ) {
      return;
    }

    logger.log('VideoStreamPlayerRealtime: joinStage()');

    setIsJoining(true);

    stageRef.current = new IVSBroadcastClient.Stage(videoStream?.playback_participant_token, {
      stageStreamsToPublish() {
        return [];
      },
      shouldPublishParticipant() {
        return true;
      },
      shouldSubscribeToParticipant() {
        return IVSBroadcastClient.SubscribeType.AUDIO_VIDEO;
      },
    });

    stageRef.current.on(IVSBroadcastClient.StageEvents.STAGE_CONNECTION_STATE_CHANGED, (state) => {
      logger.log('VideoStreamPlayerRealtime: STAGE_CONNECTION_STATE_CHANGED:', { state });
      const wasConnected: boolean = state === IVSBroadcastClient.StageConnectionState.CONNECTED;
      setIsConnected(wasConnected);
      if (wasConnected) {
        setIsJoining(false);
      }
    });

    stageRef.current.on(IVSBroadcastClient.StageEvents.STAGE_PARTICIPANT_JOINED, (participant) => {
      logger.log('VideoStreamPlayerRealtime: STAGE_PARTICIPANT_JOINED:', { participant });
    });

    stageRef.current.on(
      IVSBroadcastClient.StageEvents.STAGE_PARTICIPANT_STREAMS_ADDED,
      (participant, streams) => {
        let streamsToDisplay = [...streams];
        if (participant.isLocal) {
          // Ensure to exclude local audio streams, otherwise echo will occur
          streamsToDisplay = streams.filter(
            (stream) => stream.streamType === IVSBroadcastClient.StreamType.VIDEO,
          );
        }

        logger.log('VideoStreamPlayerRealtime: STAGE_PARTICIPANT_STREAMS_ADDED: ', {
          participant,
          streams,
          streamsToDisplay,
        });

        if (videoElementRef.current) {
          videoElementRef.current.autoplay = true;
          videoElementRef.current.playsInline = true;
          videoElementRef.current.srcObject = new MediaStream();
          logger.log('VideoStreamPlayerRealtime: videoElementRef.current.srcObject: ', {
            srcObject: videoElementRef.current.srcObject,
          });
          streamsToDisplay.forEach((stream) =>
            (videoElementRef.current?.srcObject as MediaStream)?.addTrack(stream.mediaStreamTrack),
          );
        }
      },
    );

    stageRef.current.on(IVSBroadcastClient.StageEvents.STAGE_PARTICIPANT_LEFT, (participant) => {
      logger.log('VideoStreamPlayerRealtime: STAGE_PARTICIPANT_LEFT: ', { participant });
    });

    try {
      await stageRef.current.join();
    } catch (error) {
      setIsJoining(false);
      setIsConnected(false);
      logger.error('VideoStreamPlayerRealtime: joinStage() error: ', { error });
    }
  }, [
    isConnected,
    isJoining,
    videoStream?.ivs_stream_state,
    videoStream?.playback_participant_token,
  ]);

  const leaveStage = useCallback(async () => {
    logger.log('VideoStreamPlayerRealtime: leaveStage()');
    stageRef.current?.leave();
    setIsJoining(false);
    setIsConnected(false);
  }, []);

  useEffect(() => {
    if (videoStream?.ivs_stream_state === 'START' && videoStream?.playback_participant_token) {
      joinStage();
    } else {
      leaveStage();
    }
  }, [
    joinStage,
    leaveStage,
    videoStream?.ivs_stream_state,
    videoStream?.playback_participant_token,
  ]);

  if (!agent) {
    return null;
  }

  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={videoElementRef}
        width="100%"
        height={theme.dimensions.videoStreamPlayerHeight}
        playsInline
        controls
        disablePictureInPicture
        autoPlay
        data-id="video-stream-player"
        style={{
          backgroundColor: VIDEO_PLAYER_BACKGROUND_COLOR,
          borderRadius: '6px',
          overflow: 'hidden',
        }}
      />
    </div>
  );
});

VideoStreamPlayerRealtime.displayName = 'VideoStreamPlayerRealtime';

export default VideoStreamPlayerRealtime;
